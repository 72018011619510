import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  IoMdOpen,
  IoIosArrowForward,
  IoIosRepeat,
  IoIosCog,
  IoIosCheckbox,
} from "react-icons/io";

import Image from "../components/image";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  CustomerCard,
  CallToAction,
  ImageGraphic,
  Breadcrumb,
} from "../components/site/";

import PayHeroPlusIRD from "../images/logos/PayHero/PayHeroPlusIRD.svg";

const PaydayFiling = (props) => {
  const customerData = useStaticQuery(graphql`
    query {
      allContentfulCustomerStory(
        filter: { name: { in: ["The Source Bulk Foods", "Dignity"] } }
      ) {
        edges {
          node {
            name
            url
            heroQuote
            industry
            banner {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            logo {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Inland Revenue + PayHero Integration | Automated Payday Filing"
        description="Manage payday filing the fast and easy way. Our smart IRD payday filing integration automatically files your PAYE every time you pay your staff."
        pathname={props.location.pathname}
      />
      <Breadcrumb
        parentPage="Integrations"
        parentPageLink="/add-ons"
        currentPage="Inland Revenue"
      />
      <Container>
        <Wrapper>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={30} centerOnMobile>
              <img
                src={PayHeroPlusIRD}
                alt="PayHero + Inland Revenue Integration"
                height="40"
              />
              <Box stackGap={10}>
                <h1 className="-fontLarge">
                  <span className="-fontPrimary">Payday Filing, </span>sorted
                  now and always
                </h1>
                <h4>
                  No need for extra admin with PayHero’s payday filing. Not only
                  do we calculate your payroll taxes, we’ll also file them with
                  Inland Revenue every time you pay your staff.
                </h4>
              </Box>
              <FlexButtons>
                <Button to="/signup" className="primary -lg gtm-cta">
                  Get Started with PayHero
                </Button>
              </FlexButtons>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="PageHeader_IRD.png"
                alt="IRD & PayHero Integration"
                rounded
                addShadow
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={40} className="-textCenter">
            <Box size={33}>
              <h3>
                <IoIosRepeat />
                <br />
                Fully automated
              </h3>
              <p>
                The easiest way to manage payday filing is to let our{" "}
                <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>payroll</Link> software do it for you. It’s
                100% automated so you’ll never need to think about PAYE filing
                again.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosCheckbox />
                <br />
                No extra work
              </h3>
              <p>
                With PayHero, payday filing becomes part of your regular payroll
                process. There’s not so much as an extra click required on your
                part.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosCog />
                <br />
                Easy set up
              </h3>
              <p>
                With our smart Inland Revenue integration, just connect your
                PayHero account to myIR once and you’re done. It’s as easy as
                that.
              </p>
            </Box>
          </Row>
          <hr />
          <Row stackGap={60} alignCenter mobileReverse>
            <Box size={50}>
              <Image
                alt="PAYE Returns | Payroll with Payday Filing | PayHero"
                filename="PAYEReturns.png"
                maxWidth={500}
                centerImage
                addShadow
                rounded
              />
            </Box>
            <Box stackGap={20} size={50}>
              <h2>What is payday filing?</h2>
              <h4>
                Payday filing was introduced in 2019 as a compulsory new way of
                reporting employment information that requires employers to
                submit PAYE information to Inland Revenue within two days of
                paying staff.
              </h4>
              <h4>
                PayHero integrates with Inland Revenue so payday filing is
                automatically completed with each pay run.
              </h4>
            </Box>
          </Row>
          <Row stackGap={30}>
            {customerData.allContentfulCustomerStory.edges.map((edge, i) => {
              return (
                <Box size={50} key={i}>
                  <CustomerCard
                    slug={edge.node.url}
                    banner={edge.node.banner.gatsbyImageData}
                    logo={edge.node.logo.gatsbyImageData}
                    quote={edge.node.heroQuote}
                    industry={edge.node.industry}
                    company={edge.node.name}
                  />
                </Box>
              );
            })}
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper className="-textCenter">
          <div>
            <h2>How to do payday filing with PayHero</h2>
            <h4>Get started with payday filing in three easy steps.</h4>
          </div>
          <Row stackGap={80} alignCenter>
            <Box size={33} stackGap={30}>
              <Image
                alt="Connect to Inland Revenue | Payroll with Payday Filing | PayHero"
                filename="PaydayFiling_PayHeroGraphic.png"
                maxWidth={250}
                centerImage
                addShadow
              />
              <p>1. Head to Manage {">"} Integrations in PayHero.</p>
            </Box>
            <Box size={33} stackGap={30}>
              <Image
                alt="Connect to Inland Revenue | Payroll with Payday Filing | PayHero"
                filename="PaydayFiling_PayHeroAuthorise.png"
                maxWidth={250}
                centerImage
                addShadow
              />
              <p>2. Click to connect to Inland Revenue.</p>
            </Box>
            <Box size={33} stackGap={30}>
              <Image
                alt="Connect to Inland Revenue | Payroll with Payday Filing | PayHero"
                filename="MyIR-Graphic.png"
                maxWidth={250}
                centerImage
                addShadow
              />
              <p>
                3. Securely login to myIR and authorise the connection with
                PayHero.
              </p>
            </Box>
          </Row>
          <a
            className="link-floating -center"
            href="https://support.payhero.co.nz/hc/en-us/articles/360002665876-Inland-Revenue-Integration"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Help Connecting <IoMdOpen css={{ top: "2px", left: "2px" }} />
          </a>
        </Wrapper>
      </Container>
      <CallToAction
        title="Automate your payday filing now"
        description="Don’t waste your time with the extra admin - let PayHero handle that for you."
        showSalesButton
      />
      <Container bg="lightGrey">
        <Wrapper>
          <Row stackGap={40}>
            <Box size={50}>
              <h2 className="-textCenter">Payday filing FAQs</h2>
            </Box>
            <Box size={50} stackGap={40}>
              <div>
                <p>
                  <b>How does payday filing work in PayHero?</b>
                </p>
                <p>
                  We’ve developed a direct integration with Inland Revenue so we
                  can submit your tax returns electronically.
                </p>
                <p>
                  Simply connect your account to myIR once and your payroll
                  filing will be taken care of automatically when you finalise
                  each pay. There’s no need to manually file anything via myIR.
                </p>
              </div>
              <div>
                <p>
                  <b>Do I need to contact IRD to get set up?</b>
                </p>
                <p>
                  No. As long as you have a myIR account for your company you
                  can connect to that directly from PayHero and authorise payday
                  filing without talking to IRD. New companies need to contact
                  IRD to register as an employer and get a new myIR account set
                  up.
                </p>
              </div>
              <div>
                <p>
                  <b>Does payday filing cost extra in PayHero?</b>
                </p>
                <p>No. Payday filing is included in the PayHero pricing.</p>
              </div>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper className="-textCenter">
          <div>
            <h2>A better way to payroll</h2>
            <h4>
              Pay your employees accurately and on time with PayHero's easy to
              use and 100% IRD compliant online{" "}
              <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>payroll</Link> software.
            </h4>
          </div>
          <Row stackGap={40}>
            <Box size={25}>
              <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>
                <h3>Trusted Payroll</h3>
              </Link>
              <p>
                Trusted by thousands of NZ companies, PayHero is fully compliant
                with payroll and holiday laws and automatically works out pay,
                taxes, holidays and leave.
              </p>
            </Box>
            <Box size={25}>
              <Link to={process.env.GATSBY_SITE_URL + '/employee-self-service'}>
                <h3>Mobile Apps</h3>
              </Link>
              <p>
                It’s easy to capture employee hours straight into payroll with
                our employee mobile app, or by clocking staff in and out with
                our photo time clock app.
              </p>
            </Box>
            <Box size={25}>
              <Link to={process.env.GATSBY_SITE_URL + '/employee-self-service'}>
                <h3>Employee Self Service</h3>
              </Link>
              <p>
                Employees can see their leave balances and request leave online.
                Managers can approve or decline the request from an email. It
                couldn’t be easier.
              </p>
            </Box>
            <Box size={25}>
              <Link to="/xero">
                <h3>Xero integration</h3>
              </Link>
              <p>
                Our powerful Xero integration lets you send payroll data
                straight to your chart of accounts with advanced cost tracking
                options and fast reconciliation.
              </p>
            </Box>
          </Row>
          <Link className="link-arrow -center" to={process.env.GATSBY_SITE_URL + '/payroll'}>
            Browse All Payroll Features <IoIosArrowForward />
          </Link>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default PaydayFiling;
